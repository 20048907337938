$(document).ready(function () {
    handleNavbar();
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleQuicklink();
    convertToTap();
    handleStickyNavbar();
    searchComponent();

    $('body').addClass("loading");

    $(window).resize(function() {
        handleQuicklink();
    });
});

$(window).on("load", function() {

    handleFooter();
    $(window).trigger('resize');
});

function handleQuicklink() {
    var element = $('.quicklink');
    var tileWidth = element.outerWidth( true );
    var tileHeight = tileWidth;

    element.height(tileHeight)
}

function handleNavbar() {
    $("body.is_tablet #nav-main li.has-subitems > a, body.is_tablet #nav-top li.has-subitems > a").click(function(e){

        if($(this).parent().hasClass("show")){
            $(this).parent().removeClass("show");
            location.href = e.attr('href',url);
        } else {
            $("body.is_tablet #nav-main li.has-subitems, body.is_tablet #nav-top li.has-subitems").removeClass("show");
            $(this).parent().addClass("show");
        }
        e.preventDefault();

    });
}

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {
    $('.owl-logo-carousel').owlCarousel({
        items:1,
        loop: true,
        margin: 20,
        autoplayTimeout: 6000,
        autoplay: true,
        mouseDrag: false,
        responsive: {
            0 : {
                items:1,
                dots: false
            },
            480 : {
                items:2,
                dots: false
            },
            768 : {
                items:3,
                dots: false
            },
            1024 : {
                items:4,
                dots: true
            },
            1600 : {
                items:4,
                dots: true
            }
        }
    })
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
	progressiveImageLoader();
    progressiveBackgroundImage();
};

function progressiveBackgroundImage() {

    var placeholder = $(".bg-progressive");

    placeholder.each(function(index,element) {

        var imgUrlSmall = $(element).data('small');
        var imgUrlLarge = $(element).data('large');

        var imageWrapperSmall = $('<div class="background-image-small"></div>');
        var imageWrapperLarge = $('<div class="background-image-large"></div>');

        $(imageWrapperSmall).css("background-image","url(" + imgUrlSmall +")");
        $(imageWrapperLarge).css("background-image","url(" + imgUrlLarge +")");

        $(imageWrapperSmall).appendTo(element);
        $(imageWrapperLarge).appendTo(element);

        var img = new Image();
        img.src = imgUrlLarge;

        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };
    });
}

function progressiveImageLoader() {
	var placeholder = $("figure.figure-progressive");

	placeholder.each(function(index,element) {

		//load small image
		var imageWrapperSmall = $('<img class="image-small">');
        imageWrapperSmall.attr('src', $(element).attr('data-small'));

		// //load large image
        var imageWrapperLarge = $('<img class="image-large">');
        imageWrapperLarge.attr('src', $(element).attr('data-large')).attr('srcset', $(element).attr('data-srcset'));

        imageWrapperSmall.appendTo(element);
        imageWrapperLarge.appendTo(element);

        var img = new Image();
        img.src = $(element).attr('data-large');

        //create image in DOM
        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };

    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function convertToTap() {
    $(".tabs").each(function (index, element) {

        var tabsContainer = $(element).find(".tab-container");
        var tabsComponent = tabsContainer.find(".tab-component");
        var accordionElement = $('<div class="accordion" id="accordionComponent"></div>');

        $(accordionElement).appendTo(tabsContainer);

        var $newAccordionItemTitle = new Array();
        var $newAccordionItemText = new Array();
        //
        tabsComponent.find(".nav-tabs .nav-item").each(function (index, element) {
            $newAccordionItemTitle.push(element.text);
            $(accordionElement).append('<div class="card"><div class="card-header" id="heading'+ index +'" data-toggle="collapse" data-target="#collapse'+ index +'" aria-controls="collapse'+ index +'">' + $newAccordionItemTitle[index] +'<span class="custom-arrow"></span></div></div>');

        });

        tabsComponent.find(".tab-content .tab-pane").each(function (index, element) {
            $newAccordionItemText.push($(this).text());
            accordionElement.find('.card:nth-child(' + (index + 1) +')').append('<div class="collapse" id="collapse'+ index +'" data-parent="#accordionComponent"><div class="card-body">'+ $newAccordionItemText[index] +'</div></div>');
            accordionElement.find('.card:first-child .card-header').attr("aria-expanded","true");
            accordionElement.find('.card:first-child .collapse').addClass("show");
        });

        $(window).resize(function() {
            var screenSize = $( window ).outerWidth();

            if (screenSize < 768) {
                $(accordionElement).appendTo(tabsContainer);
                $(tabsComponent).detach();
            }
            else {
                $(tabsContainer).find(accordionElement).detach();
                $(tabsComponent).appendTo(tabsContainer);
            }
        });

    });
}

function handleStickyNavbar() {
    var navbar = $("#navbar");

    navbar.removeClass("sticky");

    $(window).scroll(function() {

        if(navbar !== undefined && navbar !== null){
            var offsetTopPosition = navbar.offsetTop;

            if (window.pageYOffset >= 1) {
                navbar.addClass("sticky")
            } else {
                navbar.removeClass("sticky");
            }
        }
    });

}

function searchComponent() {
    var searchComponent = $(".search-component");
    var searchButton = $("#search");

    if(searchComponent != "" && searchButton != ""){
        $(searchButton).on("click", function () {
            $("body").toggleClass("search-component--open");
        })
    }

    $(searchComponent).on("click", function (e) {
        if($(e.target).is('.search-component__input')){
            e.preventDefault();
            return;
        }
        $("body").removeClass("search-component--open");
    })
}
